import Cookies from 'js-cookie';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import { easepick, DateTime, KbdPlugin, LockPlugin, RangePlugin } from '@easepick/bundle';
import '@/css/app.css';

class App {
    menuButton = document.getElementById('menu-button');
    menuButtonOpen = this.menuButton!.querySelector('.menu-button-open');
    menuButtonClose = this.menuButton!.querySelector('.menu-button-close');
    termButtons = document.querySelectorAll('.term');
    hotelBookerWidget = document.querySelectorAll('.hotel-booker-widget');
    
    public async init() {
        this.menuButton?.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.toggle('menu-open');
            this.toggleMenuButton();
        });

        this.termButtons?.forEach((button) => {
            button.addEventListener('click', (e) => {
                const elm:HTMLElement = e.currentTarget as HTMLElement;
                if (elm) {
                    elm.classList.add('active');
                }
            });
        });

        this.hotelBookerWidget?.forEach((booker) => {
          new HotelBooker(booker as HTMLElement);
        });

        this.animatePackshot();
    }

    private toggleMenuButton() {
        this.menuButtonOpen!.classList.toggle('hidden');
        this.menuButtonClose!.classList.toggle('hidden');
    }

    private animatePackshot() {
      if (document.querySelector('.watch')) {
        const watch:HTMLElement = document.querySelector('.watch') as HTMLElement;
        function obCallback(payload:any) {
            if (payload[0].isIntersecting) {
                ob.unobserve(payload[0].target);
                payload[0].target.classList.add('animate');
            }
        }

        const ob = new IntersectionObserver(obCallback, { threshold: .5 });
        ob.observe(watch);
      }
    }
}

class AgeModal {
  ageValidateButton = document.getElementById('age-validate-button');
  modal = document.getElementById('age-modal');

  constructor() {
    this.ageValidateButton?.addEventListener('click', (e) => {
      e.preventDefault();
      this.setAgeCookie();
      this.hideAgeModal();
    });
  
    if (this.checkAgeCookie()) {
      this.hideAgeModal();
    } else {
      this.showAgeModal();
    }
  }

  private checkAgeCookie() {
    return Cookies.get('age-consent');
  }

  private setAgeCookie() {
    return Cookies.set('age-consent', '1', { expires: 7 });
  }

  private hideAgeModal() {
    if (this.modal != null) this.modal.classList.remove('open');
    document.body.classList.remove('open-age-modal');
  }

  private showAgeModal() {
    if (this.modal != null) this.modal.classList.add('open');
    document.body.classList.add('open-age-modal');
  }
  
}

class HotelBooker {
  target:HTMLElement;
  datePicker!:DatePicker;
  form:HTMLFormElement|null;

  constructor(target:HTMLElement) {
    this.target = target;
    this.form = target.querySelector('form');
    const language = document.documentElement.lang;
    const datePickerElm:HTMLElement|null = target.querySelector('.datepicker');

    // Init datePicker
    if (datePickerElm) {
      this.datePicker = new DatePicker(datePickerElm, language);
    };

    if (this.form) {
      this.form.addEventListener('submit', (event) => {
        event.preventDefault();

        const e:any = event;
        if (this.datePicker) {
          const date = this.datePicker.getDate();
          if (date[0] && date[1]) {
            this.submit(e.target.action, date[0], date[1], language);
          }
        }
      });
    }
  }

  private submit(action:String, start:Date, end:Date, language = 'nl') {
    if (this.form) {
      const url = action + '?lang=' + language + '&Arrival=' + this.formatDate(start) + "&Departure=" + this.formatDate(end);
      window.open(url, '_blank');
    }
  }

  private formatDate(date:Date) {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  }
}

class DatePicker {
  target:HTMLElement;
  input:HTMLInputElement;
  selection:HTMLElement;
  start!:Date;
  end!:Date;
  language:string;
  arrow:string = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
<path d="M17.2071 8.96602C17.5976 8.57549 17.5976 7.94233 17.2071 7.5518L10.8431 1.18784C10.4526 0.797319 9.81946 0.797319 9.42893 1.18784C9.03841 1.57837 9.03841 2.21153 9.42893 2.60206L15.0858 8.25891L9.42893 13.9158C9.03841 14.3063 9.03841 14.9395 9.42893 15.33C9.81946 15.7205 10.4526 15.7205 10.8431 15.33L17.2071 8.96602ZM0.5 9.25891H16.5V7.25891H0.5V9.25891Z" fill="#AC9C52"/>
</svg>`;
  
  constructor(target:HTMLElement, language:string) {
    this.target = target;
    this.target.innerHTML = ''; // Clear div
    this.language = language;

    this.selection = document.createElement('div')
    this.selection.className = 'datepicker-selection';
    this.selection.innerHTML = 'Kies een datum';
    this.target.appendChild(this.selection);

    this.input = document.createElement('input')
    this.input.type = 'text';
    this.input.className = 'datepicker-input';
    this.target.appendChild(this.input);
 
    // Create date picker
    const picker = new easepick.create({
        element: this.input,
        css: [
          'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css'
        ],
        lang: this.language,
        zIndex: 10,
        grid: 2,
        calendars: 2,
        plugins: [
          RangePlugin,
          LockPlugin,
          KbdPlugin
          ],
        RangePlugin: {
          tooltipNumber(num) {
            return num - 1;
          },
          locale: this.getLocale(this.language),
        },
        LockPlugin: {
          minDate: new Date(),
          minDays: 2,
          inseparable: true
        },
        setup: (picker) => {
          picker.on('select', (e) => {
            const { start, end } = e.detail;
            this.start = start;
            this.end = end;
            this.selection.innerHTML = `<span class="date date-start">${start.getDate()}/${start.getMonth()+1}/${start.getFullYear()}</span>${this.arrow}<span class="date date-end">${end.getDate()}/${end.getMonth()+1}/${end.getFullYear()}</span>`;
          });
        },
    });
  }

  private getLocale(language:string) {
    switch(language) {
      case 'nl':
        return {
          one: 'nacht',
          other: 'nachten',
        }
      case 'fr':
        return {
          one: 'nuit',
          other: 'nuits',
        }
      case 'de':
        return {
          one: 'Nacht',
          other: 'Nächte',
        }
      case 'en':
      default:
        return {
          one: 'night',
          other: 'nights',
        }
    }
  }

  public getDate() {
    return [this.start, this.end];
  }
}

/* Accordion */
const accordionItems = document.querySelectorAll(".accordion-item");

accordionItems.forEach(item =>
  item.addEventListener("click", () => {
    const isItemOpen = item.classList.contains("open");
    accordionItems.forEach(item => item.classList.remove("open"));
    if (!isItemOpen) {
      item.classList.toggle("open");
    }
  })
);

/* Slider */
interface breakPoint {
  [index:number]: Object;
}

let breakPoints:any = {};
for (let index = 2; index <= 20; index++) {
  const breakPoint = (320 * index) + (30 * (index - 1)) + 40;
  breakPoints[breakPoint] = { perPage: index };
}

/* Card Slider */
const cardSliders = document.querySelectorAll<HTMLElement>('.block-cards-slider .splide');
if (cardSliders) {
  for ( var i = 0; i < cardSliders.length; i++ ) {
    var splide = new Splide( cardSliders[ i ] , {
        autoHeight: true,
        autoWidth: true,
        arrows: true,
        arrowPath: 'M17.0811 0.795478L34.4928 18.0795C35.5612 19.1402 35.5612 20.8598 34.4928 21.9205L17.0811 39.2045C16.0126 40.2652 14.2803 40.2652 13.2119 39.2045C12.1434 38.1439 12.1434 36.4243 13.2119 35.3636L25.9529 22.7159H2.35294C1.05345 22.7159 0 21.6625 0 20.363V19.637C0 18.3375 1.05344 17.2841 2.35294 17.2841H25.9529L13.2119 4.63638C12.1434 3.57575 12.1434 1.85611 13.2119 0.795478C14.2803 -0.265159 16.0126 -0.265159 17.0811 0.795478Z',
        gap: 20,
        padding: { left: 20, right: 20 },
        pagination: false,
        mediaQuery: 'min',
        // easing:  'linear',
        // breakpoints: breakPoints
    } );

    splide.on( 'overflow', function ( isOverflow ) {
        splide.options = {
            autoHeight: true,
            drag      : isOverflow ? 'free' : false,
        };

        splide.go(0);
    });

    splide.mount();
  }
}

/* Image Slider */
const imageSliders = document.querySelectorAll<HTMLElement>('.block-image-slider .splide');
if (imageSliders) {
  for ( var i = 0; i < imageSliders.length; i++ ) {
    var splide = new Splide( imageSliders[ i ] , {
        autoHeight: true,
        autoWidth: false,
        arrows: true,
        arrowPath: 'M17.0811 0.795478L34.4928 18.0795C35.5612 19.1402 35.5612 20.8598 34.4928 21.9205L17.0811 39.2045C16.0126 40.2652 14.2803 40.2652 13.2119 39.2045C12.1434 38.1439 12.1434 36.4243 13.2119 35.3636L25.9529 22.7159H2.35294C1.05345 22.7159 0 21.6625 0 20.363V19.637C0 18.3375 1.05344 17.2841 2.35294 17.2841H25.9529L13.2119 4.63638C12.1434 3.57575 12.1434 1.85611 13.2119 0.795478C14.2803 -0.265159 16.0126 -0.265159 17.0811 0.795478Z',
        gap: 20,
        padding: { left: 20, right: 20 },
        pagination: false,
        mediaQuery: 'min',
        type   : 'loop',
        breakpoints: {
          940: {
            autoWidth: true,
          }
        }
    } );

    splide.on( 'overflow', function ( isOverflow ) {
        splide.options = {
            drag      : isOverflow,
        };

        splide.go(0);
    });

    splide.mount();
  }
}

/* Cookie Consent */
const cookieConsentConfig:CookieConsent.CookieConsentConfig = {
  onFirstConsent: ({cookie}) => {
      window?.dataLayer?.push({ 'event': 'client-consent-update' });
  },
  onChange: ({cookie}) => {
      window?.dataLayer?.push({ 'event': 'client-consent-update' });
  },
  guiOptions: {
      consentModal: {
          layout: "box inline",
          position: "bottom right",
          equalWeightButtons: true,
          flipButtons: false
      },
      preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false
      }
  },
  categories: {
      necessary: {
          readOnly: true
      },
      functionality: {},
      analytics: {},
      marketing: {}
  },
  language: {
      default: "nl",
      autoDetect: "document",
      translations: {
        nl: {
            consentModal: {
                title: "Het Anker gebruikt cookies om je surfgedrag te verbeteren.",
                description: "Sommige cookies zijn noodzakelijk om de website optimaal te laten functioneren. Andere cookies zijn optioneel en worden gebruikt om uw surfervaring op deze website te verbeteren. Als u op ‘Alle cookies aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Wilt u liever zelf kiezen welke cookies u aanvaardt? Klik op ‘Cookievoorkeuren beheren’. Meer info leest u in het privacy- en cookiebeleid van hetanker.be",
                acceptAllBtn: "Alles aanvaarden",
                acceptNecessaryBtn: "Alles weigeren",
                showPreferencesBtn: "Voorkeuren aanpassen",
                footer: "<a href=\"/privacybeleid\">Privacybeleid</a>\n<a href=\"/algemene-voorwaarden\">Algemende voorwaarden</a>"
            },
            preferencesModal: {
                title: "Cookievoorkeuren",
                acceptAllBtn: "Alles aanvaarden",
                acceptNecessaryBtn: "Alles weigeren",
                savePreferencesBtn: "Voorkeuren aanpassen",
                closeIconLabel: "Venster sluiten",
                serviceCounterLabel: "Service|Services",
                sections: [
                    {
                        title: "Cookiegebruik",
                        description: "Sommige cookies zijn noodzakelijk om de website optimaal te laten functioneren. Andere cookies zijn optioneel en worden gebruikt om uw surfervaring op deze website te verbeteren. Als u op ‘Alle cookies aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Wilt u liever zelf kiezen welke cookies u aanvaardt? Klik op ‘Cookievoorkeuren beheren’. Meer info leest u in het privacy- en cookiebeleid van hetanker.be"
                    },
                    {
                        title: "Noodzakelijke cookies <span class=\"pm__badge\">Steeds ingeschakeld</span>",
                        description: "Deze cookies zijn vereist om de website goed te laten werken en zorgen ervoor dat uw cookievoorkeuren worden bewaard.",
                        linkedCategory: "necessary"
                    },
                    {
                        title: "Analytische cookies",
                        description: "Deze cookies meten het aantal bezoekers op de website en geven een algemeen beeld van uw bezoek. Alle verzamelde gegevens zijn anoniem.",
                        linkedCategory: "analytics"
                    },
                    {
                        title: "Media cookies",
                        description: "Media cookies zijn een vorm van marketing cookies. Deze cookies worden gebruikt om de bezoekers over websites heen te volgen.",
                        linkedCategory: "marketing"
                    }
                ]
            },
        },
        fr: {
            consentModal: {
                title: "Het Anker utilise des cookies pour améliorer votre navigation.",
                description: "Certains cookies sont nécessaires pour garantir le fonctionnement optimal du site web. Les autres cookies sont optionnels et sont utilisés pour améliorer votre expérience de navigation sur ce site web. Si vous cliquez sur « Accepter tous les cookies », vous acceptez également les cookies optionnels. Vous préférez choisir vous-même quels cookies vous acceptez ? Cliquez sur « Gérer les préférences en matière de cookies ». Pour plus d'informations, veuillez consulter la politique de confidentialité et la politique en matière de cookies de hetanker.be",
                acceptAllBtn: "Tout accepter",
                acceptNecessaryBtn: "Tout refuser",
                showPreferencesBtn: "Sauvegarder mes préférences",
                footer: "<a href=\"/fr/polotique-de-confidentialité\">Polotique de confidentialité</a>\n<a href=\"/fr/conditions-générales\">Conditions générales</a>"
            },
            preferencesModal: {
                title: "Préférences en matière de cookies",
                acceptAllBtn: "Tout accepter",
                acceptNecessaryBtn: "Tout refuser",
                savePreferencesBtn: "Sauvegarder mes préférences",
                closeIconLabel: "Fermez la fenêtre",
                serviceCounterLabel: "Service|Services",
                sections: [
                    {
                        title: "Utilisation des cookies",
                        description: "Certains cookies sont nécessaires pour garantir le fonctionnement optimal du site web. Les autres cookies sont optionnels et sont utilisés pour améliorer votre expérience de navigation sur ce site web. Si vous cliquez sur « Accepter tous les cookies », vous acceptez également les cookies optionnels. Vous préférez choisir vous-même quels cookies vous acceptez ? Cliquez sur « Gérer les préférences en matière de cookies ». Pour plus d'informations, veuillez consulter la politique de confidentialité et la politique en matière de cookies de hetanker.be",
                    },
                    {
                        title: "Cookies nécessaires <span class=\"pm__badge\">Toujours allumé</span>",
                        description: "Ces cookies sont nécessaires au bon fonctionnement du site web et permettent de sauvegarder vos préférences en matière de cookies.",
                        linkedCategory: "necessary"
                    },
                    {
                        title: "Cookies analytiques",
                        description: "Ces cookies comptent le nombre de visiteurs sur le site web et offrent une image globale de votre visite. Toutes les données collectées sont anonymes.",
                        linkedCategory: "analytics"
                    },
                    {
                        title: "Cookies médias sociaux",
                        description: "Les cookies médias sociaux sont une forme de cookies marketing. Ces cookies sont utilisés pour suivre les visiteurs sur les sites web.",
                        linkedCategory: "marketing"
                    }
                ]
            },
        },
        en: {
            consentModal: {
                title: "Het Anker uses cookies to improve your browsing behaviour.",
                description: "Some cookies are necessary to ensure that the website functions optimally. Other cookies are optional and are used to improve your browsing experience on this website. If you click on ‘Accept all cookies’, you also accept the optional cookies. Would you prefer to choose which cookies you accept yourself? Click on ‘Manage cookie preferences’. For more information, please consult the privacy policy and cookie policy of hetanker.be.",
                acceptAllBtn: "Accept all",
                acceptNecessaryBtn: "Reject all",
                showPreferencesBtn: "Save my preferences",
                footer: "<a href=\"/en/privacy-policy\">Privacy policy</a>\n<a href=\"/en/general-terms-and-conditions\">General terms and conditions</a>"
            },
            preferencesModal: {
                title: "Cookie preferences",
                acceptAllBtn: "Accept all",
                acceptNecessaryBtn: "Reject all",
                savePreferencesBtn: "Save my preferences",
                closeIconLabel: "Close window",
                serviceCounterLabel: "Service|Services",
                sections: [
                    {
                        title: "Use of cookies",
                        description: "Some cookies are necessary to ensure that the website functions optimally. Other cookies are optional and are used to improve your browsing experience on this website. If you click on ‘Accept all cookies’, you also accept the optional cookies. Would you prefer to choose which cookies you accept yourself? Click on ‘Manage cookie preferences’. For more information, please consult the privacy policy and cookie policy of hetanker.be.",
                    },
                    {
                        title: "Necessary cookies <span class=\"pm__badge\">Always enabled</span>",
                        description: "These cookies are necessary for the proper functioning of the website and allow you to save your cookie preferences.",
                        linkedCategory: "necessary"
                    },
                    {
                        title: "Analytical cookies",
                        description: "These cookies count the number of visitors to the website and provide an overall picture of your visit. All data collected is anonymous.",
                        linkedCategory: "analytics"
                    },
                    {
                        title: "Social media cookies",
                        description: "Social media cookies are a form of marketing cookie. These cookies are used to track visitors to websites.",
                        linkedCategory: "marketing"
                    }
                ]
            },
        },
    }
  }
};

CookieConsent.run(cookieConsentConfig);

let app = new App();
app.init();

let ageModal = new AgeModal();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
}